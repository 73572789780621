body {
  .ant-picker {
    height: 40px;
  }

  .ant-picker-small {
    height: 32px;
  }
  //TODO: Не получилось сделать их более читабельными, печалька :(

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before {
    background: var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before {
    background: var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before {
    background: var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before {
    background: var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before {
    background: var(--accent-darken-color);
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
    background: var(--accent-darken-color);
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
    background: var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
    border-top: 1px dashed var(--accent-darken-color);
    border-bottom: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
    border-top: 1px dashed var(--accent-darken-color);
    border-bottom: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after {
    border-top: 1px dashed var(--accent-darken-color);
    border-bottom: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after {
    border-top: 1px dashed var(--accent-darken-color);
    border-bottom: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
    border-top: 1px dashed var(--accent-darken-color);
    border-bottom: 1px dashed var(--accent-darken-color);
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
    border-left: 1px dashed var(--accent-darken-color);
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after {
    border-left: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after {
    border-left: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
    border-left: 1px dashed var(--accent-darken-color);
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
    border-right: 1px dashed var(--accent-darken-color);
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after {
    border-right: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after {
    border-right: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after {
    border-right: 1px dashed var(--accent-darken-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover .ant-picker-cell-inner {
    color: var(--white-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
    color: var(--white-color);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner {
    color: #fff;
  }

  .ant-picker-clear > .anticon-close-circle {
    background: var(--input-bg-color);
    font-size: 18px;
    color: var(--text-color);
  }

  .ant-picker-focused {
    border-right-width: 2px !important;
  }
}
