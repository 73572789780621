body {
  .ant-avatar {
    background-color: var(--scroll-color);
    color: var(--gray-color);
  }

  .ant-avatar:hover {
    color: var(--white-color);
  }

  .ant-avatar-sm {
    width: 20px;
    height: 20px;
  }

  .ant-avatar-sm .custom-icon {
    font-size: 20px;
  }

  .ant-avatar .custom-icon {
    color: var(--gray-color);
  }
}
