body {
  textarea.ant-input {
    min-height: 96px !important;
    max-height: 600px !important;
    padding: 7px 11px !important;
  }

  textarea.ant-input::placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }
}
