body {
  .ant-progress-circle-trail {
    stroke: var(--circle-trail-color);
  }

  .ant-progress-inner {
    background-color: var(--scroll-color);
  }

  .ant-progress-small .ant-progress-bg {
    height: 4px !important;
  }

  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: var(--accent-color);
  }
}
