body {
  .ant-modal {
    top: 0;
    padding-bottom: 0;
  }

  .ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal__footer .ant-btn + .ant-btn {
    margin-left: 12px;
  }

  .ant-modal-close-x {
    display: none !important;
  }

  .ant-modal-mask,
  .ant-image-preview-mask {
    background-color: rgba(33, 43, 54, .9);
  }

  .ant-modal-content {
    border-radius: 4px;
    box-shadow: var(--box-shadow-modal);
    background: var(--white-color);
  }

  .modal {
    padding: 0 32px;
    font-size: 15px;
    line-height: 15px;
    color: #202020;
  }

  .modal__footer {
    display: flex;
    justify-content: flex-end;
    margin: -4px 0 0;
    padding: 32px 0;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .modal-layout .ant-modal-header {
    display: flex;
    height: auto;
    align-items: center;
    padding: 32px;
    border-bottom: none;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    background: var(--white-color);
  }

  .modal-layout .ant-modal-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.24;
    color: #1b2733;
  }

  .modal-layout .ant-modal-close-x {
    width: 50px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    color: rgba(255, 255, 255, .5);
  }

  .modal-layout .ant-modal-close-x:hover {
    color: var(--white-color);
  }

  .modal-layout .ant-modal-body {
    padding: 0;
    border-radius: var(--border-radius);
    overflow: hidden;
  }

  .ant-modal .ant-form.ant-form-vertical {
    max-height: calc(100vh - var(--header-modal) - var(--footer-modal) - var(--vertical-indent-modal));
    margin-right: -32px;
    padding-right: 32px;
    overflow-y: auto;
  }

  .ant-modal .ant-breadcrumb-link {
    padding: 0 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .ant-modal .body__title-breadcrumbs .ant-breadcrumb-link {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-modal .ant-breadcrumb-link:hover {
    background: var(--btn-default--bg__hover);
  }

  .ant-modal .ant-breadcrumb-overlay-link .ant-breadcrumb-link {
    background: inherit;
  }

  .ant-modal .ant-breadcrumb > span:last-child {
    max-width: calc(100vw - var(--editor-width) - var(--first-breadcrumbs-link-with-setting-pagination__title));
    overflow: hidden;
    color: rgba(0, 0, 0, .45);
  }

  .ant-modal .ant-breadcrumb-separator {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .ant-modal .ant-breadcrumb .anticon {
    font-size: 16px;
  }

  .ant-modal .ant-dropdown-trigger.ant-breadcrumb-overlay-link,
  .ant-modal .ant-breadcrumb-link {
    display: flex;
    align-items: center;
  }

  .ant-modal .ant-breadcrumb-link + .anticon {
    display: none;
  }

  .ant-modal .ant-breadcrumb {
    display: flex;
    height: 32px;
    min-width: 100%;
    align-items: center;
    font-size: 20px;
    line-height: 32px;
    color: var(--text-color);
  }

  .ant-modal .ant-breadcrumb > span {
    display: flex;
    align-items: center;
  }
}
