body {
  .ant-tag {
    height: 20px;
    border: none;
    border-radius: 100px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }
}
