body {
  .ant-radio-wrapper:hover .ant-radio:not(.ant-radio-disabled),
  .ant-radio:not(.ant-radio-disabled):hover .ant-radio-inner,
  .ant-radio-input:not(.ant-radio-disabled):focus + .ant-radio-inner {
    border-color: var(--accent-color) !important;
  }

  .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner {
    border-color: var(--accent-color);
  }

  .ant-radio-wrapper .ant-radio-inner {
    border: 2px solid var(--input-placeholder-color);
  }

  .ant-radio-wrapper-checked .ant-radio-inner:after {
    top: 2px;
    left: 2px;
  }
}
