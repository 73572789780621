body {
  .ant-tabs-top .ant-tabs-nav-wrap .ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-top .ant-tabs-tab-btn:focus,
  .ant-tabs-top .ant-tabs-tab-remove:focus,
  .ant-tabs-top .ant-tabs-tab-btn:active,
  .ant-tabs-top .ant-tabs-tab-remove:active {
    font-weight: 400;
    transition: none;
    color: #000;
  }

  .ant-tabs-top .ant-tabs-tab-btn {
    transition: none;
  }

  .ant-tabs-top .ant-tabs-nav-wrap .ant-tabs-tab {
    position: relative;
    height: 46px;
    font-size: 14px;
    transition: none;
    color: rgba(26, 38, 50, .54);
  }

  .ant-tabs-top .ant-tabs-nav-wrap .ant-tabs-tab:hover {
    box-shadow: inset 0 -1px 0 var(--accent-color) !important;
    z-index: 1;
    color: var(--text-color) !important;
  }

  .ant-tabs-top .ant-tabs-nav-wrap {
    border-bottom-color: var(--border-color);
  }

  .ant-tabs-top .ant-tabs-ink-bar {
    background: var(--accent-color) !important;
  }

  .ant-tabs {
    line-height: 1.24;
  }
}
