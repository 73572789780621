:root {
  --app-height: 100vh;
  --app-inset-top: .01px;
  --app-inset-bottom: .01px;
  --app-inset-top: constant(safe-area-inset-top);
  --app-inset-top: env(safe-area-inset-top);
  --app-inset-bottom: constant(safe-area-inset-bottom);
  --app-inset-bottom: env(safe-area-inset-bottom);
  --font-family-base: 'AtlasGrotesk', sans-serif;
  --header-height: 72px;
  --preview-header-height: 64px;
  --footer-help-height: 64px;
  --second-header-height: 88px;
  --body-padding: 40px;
  --body-margin: -40px;
  --body-height: calc(100vh - var(--header-height));
  // sizes
  --toggle-button-size: 32px;
  --border-radius: 4px;
  // Unsafe project colors
  --white-color: #fff;
  --black-color: #000;
  --body-bg-color: var(--white-color);
  --disabled-color: rgba(0, 0, 0, .25);
  --scroll-bg: transparent;
  --accent-color: #01b075;
  --accent-bg-color: #ebf9f4;
  --accent-darken-color: '#167857';
  --link-color: #3d8bf4;
  --link-color-hover: #065fd4;
  --app-work-bg: #f8f9fb;
  --border-color: #f4f5f7;
  --bg-preview-body: #f8f9fb;
  --bg-preview-footer: #0000000a;
  --bg-preview-help-footer: var(--white-color);
  --bg-default-color: #eaebf0;
  --toggle-button-icon-bg: #f5f5f5;
  --toggle-button-icon-color: rgba(0, 0, 0, .32);
  --toggle-button-icon-bg--active: var(--accent-bg-color);
  --toggle-button-icon-color--active: #01b075;
  --light-blue-color: var(--accent-bg-color);
  --outline: #637282;
  --accent-secondary: #b1d1fb;
  --accent-border-secondary: #3d8bf4;
  --text-color: #1d2b4b;
  --preview-text-color: #030303;
  --gray-color-100: #f7f7f7;
  --gray-color-200: #b2b8be;
  --gray-color-300: #c7c7c7;
  --gray-color: #697787;
  --accent-border-color: #e9ebf0;
  --scroll-color: #e9ebf0;
  --scroll-color--hover: #888;
  --input-bg-color: #f4f5f7;
  --input-border--default: 2px solid #f4f5f7;
  --input-border--focus: 2px solid #b1d1fb;
  --input-border--error: 2px solid #fedddc;
  --input-bg--error: #ffe9e4;
  --input-placeholder-color: #b2b8be;
  --box-shadow--default: 0 0 0 2px #f4f5f7;
  --circle-trail-color: #f1f5fa;
  --box-shadow--focus: 0 0 0 2px #b1d1fb;
  --box-shadow--error: 0 0 0 2px #fedddc;
  --box-shadow-modal: 0 0 15px #6977871a;
  --header-modal: 88px;
  --footer-modal: 96px;
  --vertical-indent-modal: 100px;
  --editor-block-w: 800px;
  --editor-block-h: 450px;
  --darken-filter: brightness(90%);
  --error-text-color: #f37458;
  --error-icon-color: #faad14;
  --elements-border-radius: 6px;
  --thumb-box-shadow: 0 0 7px rgba(33, 43, 54, .2);
  --card-box-shadow: 0 0 15px rgba(105, 119, 135, .1);
  --btn-box-shadow: 0 0 10px rgba(0, 0, 0, .05);
  --preview-menu-background: #1d2b4bf5;
  // Stable project colors
  --color-0: #fff;
  --color-1: #000;
  --color-2: #f4f5f7;
  --color-3: #e9ebf0;
  --color-4: #aeb6bf;
  --color-5: #697787;
  --color-6: #1d2b4b;
  --color-7: #e8f9f1;
  --color-8: #cde9dc;
  --color-9: #01b075;
  --color-10: #288767;
  --color-11: #18a0fb;
  --color-12: #3d8bf4;
  --color-13: #f2ebdc;
  --color-14: #f2994a;
  --color-15: #f00;
  --color-16: #736eef;
  --color-17: #f1bf33;
  --color-18: #d2664a;
  --color-19: #ef5b3d;
  --color-20: #36aa55;
  --color-21: #3d8bf4;
}

body {
  a {
    color: var(--link-color);
  }
  a:hover,
  a:active,
  a:focus {
    color: var(--link-color-hover);
  }
}

#root {
  height: 100%;
}

[data-whatintent='mouse'] body *:focus,
[data-whatintent='mouse'] body .ant-btn:focus {
  outline: none;
}

@import './theme/reset.less';
@import './theme/animations.less';
// antd
@import './theme/antd.alert.less';
@import './theme/antd.checkbox.less';
@import './theme/antd.avatar.less';
@import './theme/antd.btn.less';
@import './theme/antd.form.less';
@import './theme/antd.input.less';
@import './theme/antd.modal.less';
@import './theme/antd.pagination.less';
@import './theme/antd.radio.less';
@import './theme/antd.range.less';
@import './theme/antd.select.less';
@import './theme/antd.tabs.less';
@import './theme/antd.table.less';
@import './theme/antd.textarea.less';
@import './theme/antd.datepicker.less';
@import './theme/antd.menu.less';
@import './theme/antd.number.less';
@import './theme/antd.picker.less';
@import './theme/antd.tree.less';
@import './theme/antd.tags.less';
@import './theme/antd.drawer.less';
@import './theme/antd.dropdown.less';
@import './theme/antd.progress.less';
@import './theme/antd.tooltip.less';
@import './theme/antd.notifications.less';
@import './theme/antd.space.less';
@import './theme/antd.breadcrumbs.less';
@import './theme/antd.switch.less';
@import './theme/antd.slide.less';
@import './theme/antd.popover.less';
// uppy
@import './theme/uppy.fileportal.less';
// country-picker
@import './theme/phone.country.less';
// global
@import './theme/global.less';
// fonts
@import './theme/fonts.less';

@font-family: Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color: #1D2B4B;@primary-color: #01b075;@border-radius-base: 4px;@border-color-base: #E9EBF0;@slider-handle-color: #01b075;@slider-track-background-color: #01b075;@slider-track-background-color-hover: #01b075;@slider-rail-background-color-hover: #f0f0f0;@slider-rail-background-color: #f0f0f0;@slider-dot-border-color-active: #01b075;@slider-handle-color-hover: #01b075;@table-selection-column-width: 40px;