/* временные стили до глобального изменения иконок в проекте */

.custom-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
}

.folder-icons {
  margin-top: -4px;
  margin-right: 8px;
  font-size: 24px;
}

@font-family: Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color: #1D2B4B;@primary-color: #01b075;@border-radius-base: 4px;@border-color-base: #E9EBF0;@slider-handle-color: #01b075;@slider-track-background-color: #01b075;@slider-track-background-color-hover: #01b075;@slider-rail-background-color-hover: #f0f0f0;@slider-rail-background-color: #f0f0f0;@slider-dot-border-color-active: #01b075;@slider-handle-color-hover: #01b075;@table-selection-column-width: 40px;