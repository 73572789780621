body {
  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label,
    .ant-form-item .ant-form-item-control {
      flex: inherit;
    }
  }
  input.ant-input::placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-input-number .ant-input-number-handler-wrap {
    height: 30px;
  }

  .ant-input {
    height: 40px;
  }

  .ant-input-number {
    border: 0;
    border-radius: 4px;
  }

  .ant-form-item-label label {
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    user-select: none;
    color: var(--text-color);
  }

  .ant-form-item-label {
    padding-bottom: 16px !important;
    line-height: 17px;
  }

  .ant-form-item-with-help {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 32px;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: var(--app-work-bg) !important;
    transition: none;
  }

  .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item-label > label {
    height: auto !important;
  }

  .ant-input-affix-wrapper {
    height: 40px;
    border: var(--input-border--default);
    border-radius: 4px;
    background: var(--input-bg-color);
    transition: .2s;
    color: var(--text-color);
  }

  .ant-input-affix-wrapper-sm {
    height: 32px;
  }

  .ant-input.ant-input-sm {
    height: 32px !important;
  }

  .ant-input-affix-wrapper-sm .ant-input.ant-input-sm {
    height: 28px !important;
  }

  .ant-input-affix-wrapper-sm ~ .ant-input-group-addon {
    padding: 0 6px;
  }

  .ant-input-affix-wrapper:hover {
    border-right-width: 2px !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border: var(--input-border--focus);
    border-right-width: 2px !important;
    box-shadow: none;
    background: var(--white-color);
  }

  .ant-input-prefix + .ant-input:hover {
    box-shadow: none;
  }

  .ant-input,
  textarea[disabled],
  .ant-input[disabled],
  .ant-input[disabled]:hover {
    border: var(--input-border--default);
    border-radius: 4px;
    box-shadow: none;
    background: var(--input-bg-color);
    transition: .2s;
    color: var(--text-color);
  }

  .ant-input-group-addon {
    border: var(--input-border--default);
    background: var(--input-bg-color);
  }

  .ant-input-group-addon .ant-checkbox-wrapper {
    margin: 0 !important;
  }

  .ant-form-item-control-input .ant-form-item-control-input-content > .ant-input {
    height: 40px;
  }

  .ant-input:not([disabled]):hover {
    border: var(--input-border--focus);
    border-right-width: 2px !important;
  }

  .ant-input:not([disabled]):focus {
    border-right-width: 2px !important;
    box-shadow: none;
    background: var(--white-color);
  }

  .ant-input.ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border: var(--input-border--error);
    box-shadow: none;
    background: var(--input-bg--error);
  }

  .ant-form-item-has-error .ant-input {
    border: var(--input-border--error);
    box-shadow: none;
  }

  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-input-affix-wrapper:focus,
  .ant-form-item-has-error .ant-input-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper-focused {
    border: var(--input-border--error);
    box-shadow: none;
    background: var(--white-color);
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: 100%;
    border: 0 !important;
    box-shadow: none;
    background: rgba(233, 235, 240, .1);
    transition: .2s;
    color: var(--text-color);
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper:hover,
  .ant-input:focus,
  .ant-input-focused {
    border: var(--input-border--focus);
    box-shadow: none;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    background: var(--white-color) !important;
  }

  .ant-input-affix-wrapper .anticon.anticon-search {
    color: var(--gray-color);
  }

  // search
  .ant-input-search .ant-input {
    box-shadow: none;
  }

  .ant-input-search .ant-input:hover {
    box-shadow: none;
  }

  .ant-input-search .finder-v2__.right-children-item .ant-input-affix-wrapper {
    box-shadow: none;
  }

  .ant-input-search .ant-input:hover ~ .ant-input-search {
    border: var(--input-border--focus);
  }

  .ant-input-search:focus-within {
    border: var(--input-border--focus);
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: none;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 10px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    color: #ff4d4f;
  }

  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper {
    background: var(--input-bg--error);
  }

  .ant-input-lg {
    font-size: 14px;
  }

  .ant-input-affix-wrapper > input.ant-input-lg {
    padding: 6.5px 11px;
  }

  .ant-input-suffix,
  .ant-input-prefix {
    font-size: 14px;
    color: var(--text-color);
  }

  .ant-input-affix-wrapper-disabled {
    border-color: var(--input-bg-color) !important;
    background-color: var(--input-bg-color) !important;

    .ant-input-suffix,
    input.ant-input,
    .ant-input-prefix {
      color: var(--disabled-color) !important;
    }
  }

  .ant-input-affix-wrapper-disabled:hover {
    border-color: var(--input-bg-color);
    background-color: var(--input-bg-color);
  }
}
