* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:focus {
  outline: 1px dotted var(--outline);
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

::-moz-selection {
  background: rgb(220, 220, 220);
}

::selection {
  background: rgb(220, 220, 220) !important;
  color: inherit !important;
}

// фиксит хромоский автокомплит
&:-webkit-autofill:first-line,
&:-webkit-autofill,
&:-webkit-autofill:hover,
&:-webkit-autofill:focus,
&:-webkit-autofill:active {
  font-size: 14px;
  font-family:
    Roboto,-apple-system,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji' !important;
}

//scroll
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: var(--scroll-bg);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: var(--scroll);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scroll--hover);
}

// chorme autofill
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f4f5f7 inset !important;
  background-color: transparent !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 50px #f4f5f7 inset !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  background-color: transparent !important;
}

.ant-input-affix-wrapper > input.ant-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f4f5f7 inset;
  background-color: transparent !important;
}

.ant-input-affix-wrapper > input.ant-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 50px #f4f5f7 inset !important;
}

.ant-input-affix-wrapper > input.ant-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  background-color: transparent !important;
}

html,
body {
  -webkit-print-color-adjust: exact;
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

mark {
  padding: 0 !important;
}
