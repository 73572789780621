.root {
  height: 100vh;
}

.wrapperNotification {
  display: flex;
  width: 100%;
  height: 40px;
  max-height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 8px  0;
  background-color: var(--color-14);
  z-index: 100;
}

.wrapperInfo {
  display: flex;
  max-width: 100%;
  align-items: center;
  padding-left: 24px;
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  color: var(--color-0);
}

.warning {
  margin-right: 13px;
  font-size: 24px;
  color: var(--color-0);
}

.textInfo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.separator {
  width: 1px;
  height: 16px;
  margin: 0 12px;
  background-color: var(--color-0);
}

.closeBlock {
  margin: 0 16px;
  font-size: 24px;
  cursor: pointer;
  color: var(--color-0);
}

.dateBlock {
  display: flex;
  flex-shrink: 0;
}

.wrapperChildren {
  height: 100vh;
  &.notificationOpen {
    height: calc(100vh - 40px);
  }
}
