body {
  // .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  //   box-shadow: none;
  // }

  .ant-drawer-header-no-title .ant-drawer-close {
    background-color: var(--white-color);
    transform: translate(-9px, 3px);
    color: #1d2b4b;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
}
