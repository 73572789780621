body {
  .ant-picker-range {
    height: 40px;
    border: var(--input-border--default);
    border-radius: var(--border-radius);
    box-shadow: none;
    background: var(--input-bg-color);
    transition: .2s;
    color: var(--text-color);
  }

  .ant-picker-range:not(.ant-picker-disabled):hover {
    border: var(--input-border--focus);
    box-shadow: none;
  }

  .ant-picker-range:not(.ant-picker-disabled):focus {
    border: var(--input-border--focus);
    box-shadow: none;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: var(--accent-color);
  }
}
