body {
  .dragging:not(.ant-input) {
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 2px var(--accent-secondary);
  }

  .dragging.ant-input {
    border-color: var(--accent-secondary);
  }
}
