:root {
  --btn-primary-large--color: #fff;
  --btn-primary-large--bg: var(--accent-color);
  --btn-primary-large--color__hover: #fff;
  --btn-primary-large--bg__hover: var(--accent-darken-color);
  --btn-primary-large--border: 1px solid transparent;
  --btn-primary--color: var(--accent-color);
  --btn-primary--bg: var(--accent-bg-color);
  --btn-primary--color__hover: #288767;
  --btn-primary--bg__hover: var(--accent-color);
  --btn-primary--border: 1px solid transparent;
  --btn-default--color: #1d2b4b;
  --btn-default--bg: white;
  --btn-default--color__hover: #1d2b4b;
  --btn-default--bg__hover: #f8f9fb;
  --btn-default--border: 1px solid #e9ebf0;
  --btn-ghost-large--color: #1d2b4b;
  --btn-ghost-large--bg: transparent;
  --btn-ghost-large--color__hover: #1d2b4b;
  --btn-ghost-large--bg__hover: #f8f9fb;
  --btn-ghost-large--border: 1px solid transparent;
  --btn-ghost--color: #1d2b4b;
  --btn-ghost--bg: transparent;
  --btn-ghost--color__hover: var(--accent-color);
  --btn-ghost--bg__hover: transparent;
  --btn-ghost--border: 1px solid transparent;
  --btn-gray--bg: #f4f5f7;
}

body {
  .ant-transfer-operation .ant-btn + .ant-btn {
    margin-left: 0;
  }

  .ant-btn-group + .ant-btn-group {
    margin-left: 12px !important;
  }

  .ant-btn,
  .ant-btn:focus,
  .ant-btn:hover {
    border: var(--btn-border);
    background: var(--btn-bg);
    white-space: normal;
    color: var(--btn-color);
  }

  .ant-btn-group-lg + .ant-btn-group-lg {
    margin-left: 20px !important;
  }

  .ant-btn:focus {
    outline: 1px dotted var(--outline);
  }

  .ant-btn {
    --btn-color: var(--btn-default--color);
    --btn-bg: var(--btn-default--bg);
    --btn-border: var(--btn-default--border);

    height: 32px;
    border-radius: var(--border-radius);
    box-shadow: none !important;
    text-shadow: none;
    transition: .2s;
  }

  .ant-btn:hover {
    --btn-bg: var(--btn-default--bg__hover);
  }

  .ant-btn[data-gray]:not(.ant-btn-text) {
    --btn-border: 1px solid transparent;
  }

  .ant-btn:hover[data-gray] {
    --btn-color: var(--btn-default--color__hover);
    --btn-bg: var(--btn-default--bg__hover);
  }

  .ant-btn-text {
    --btn-color: var(--btn-ghost-large--color);
    --btn-bg: var(--btn-ghost-large--bg);
    --btn-border: var(--btn-ghost-large--border);
  }

  .ant-btn.ant-btn-ghost {
    border: var(--btn-ghost--border);
    background: var(--btn-ghost--bg);
    color: var(--btn-ghost--color);
  }

  .ant-btn.ant-btn-link {
    border: var(--btn-ghost--border);
    background: var(--btn-ghost--bg);
    color: var(--link-color);
  }

  .ant-btn.ant-btn-ghost:hover {
    border: var(--btn-ghost--border);
    background: var(--btn-ghost--bg__hover);
    color: var(--btn-ghost--color__hover);
  }

  .ant-btn.ant-btn-link:hover {
    border: var(--btn-ghost--border);
    background: var(--btn-ghost--bg__hover);
    color: var(--link-hover-color);
  }

  .ant-btn-text:hover {
    --btn-color: var(--btn-ghost--color__hover);
    --btn-bg: var(--btn-ghost--bg__hover);
  }

  .ant-btn-primary {
    transition: none;
  }

  .ant-btn-primary,
  .ant-btn-lg.ant-btn-primary[data-inverse] {
    --btn-color: var(--btn-primary--color);
    --btn-bg: var(--btn-primary--bg);
    --btn-border: var(--btn-primary--border);

    border-radius: var(--border-radius);
  }

  .ant-btn-primary:hover,
  .ant-btn-lg.ant-btn-primary[data-inverse]:hover {
    --btn-color: var(--btn-primary--color__hover);
    --btn-bg: var(--btn-primary--bg);
  }

  .ant-btn-lg {
    height: 40px;
    font-size: 14px;
  }

  .ant-btn-lg.ant-btn-text {
    --btn-color: var(--btn-ghost-large--color);
    --btn-bg: var(--btn-ghost-large--bg);
    --btn-border: var(--btn-ghost-large--border);

    border-radius: var(--border-radius);
  }

  .ant-btn-lg.ant-btn-text:hover {
    --btn-color: var(--btn-ghost-large--color__hover);
    --btn-bg: var(--btn-ghost-large--bg__hover);
  }

  .ant-btn-lg.ant-btn-primary,
  .ant-btn-primary[data-accent] {
    --btn-color: var(--btn-primary-large--color);
    --btn-bg: var(--btn-primary-large--bg);
    --btn-border: var(--btn-primary-large--border);

    border-radius: var(--border-radius);
  }

  .ant-btn-lg.ant-btn-primary:hover,
  .ant-btn-primary[data-accent]:hover {
    --btn-color: var(--btn-primary-large--color__hover);
    --btn-bg: var(--btn-primary-large--bg__hover);
  }

  .ant-btn-icon-only.ant-btn-sm {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    height: 40px;
    align-items: center;
  }

  .ant-btn-circle.ant-btn-lg,
  .ant-btn-circle-outline.ant-btn-lg {
    border-radius: 50%;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    border-color: transparent;
    background: none;
  }
}
