body {
  .ant-alert-info {
    border: 1px solid var(--accent-border-color);
    border-radius: 6px;
    background: var(--app-work-bg);
    color: var(--text-color);
  }

  .ant-alert-warning {
    border: 1px solid var(--accent-border-color);
    border-radius: 6px;
    background: var(--app-work-bg);
    color: var(--text-color);
  }

  .ant-alert {
    align-items: flex-start;
    padding: 16px;
  }

  .ant-alert .anticon {
    margin-top: 2px;
  }

  .ant-alert .ant-alert-content {
    line-height: 1.34;
  }
}
