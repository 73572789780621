.file-portal {
  position: fixed;
  width: 384px;
  height: 40px;
  top: 40px;
  right: 24px;
  z-index: 100000;
}

.file-portal:empty {
  display: none;
}
