.ant-checkbox-wrapper .ant-checkbox-inner {
  border: 2px solid var(--input-placeholder-color);
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  top: 5px;
  left: 2px;
}

.test-radio-element .ant-radio-inner {
  border: 2px solid var(--input-placeholder-color);
}

.test-radio-element .ant-radio-inner:after {
  top: 2px;
  left: 2px;
}
