:root {
  --slider-mark-text-width: 232px;
  --slider-mark-text-height: 70px;
}

body {
  .ant-slider {
    .mini-timeline .ant-slider-mark-text {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: var(--slider-mark-text-height);
      max-width: var(--slider-mark-text-width);
      flex-wrap: wrap;
      margin-top: 24px;
      padding: 0 5px;
      line-height: 1.3;
      text-align: left;
      overflow-wrap: anywhere;
      transform: translateX(0) !important;
      overflow-x: scroll;
    }
    // hide-scroll
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
