body {
  .ant-input-number-input,
  .ant-input-number-disabled .ant-input-number-input {
    height: 40px;
    border: var(--input-border--default);
    background-color: var(--input-bg-color);
  }

  .ant-input-number-input:focus {
    background: var(--white-color);
  }

  .ant-input-number:hover,
  .ant-input-number-focused {
    box-shadow: none;
  }

  .ant-input-number-disabled {
    border: none !important;
  }

  .ant-input-number-disabled:hover .ant-input-number-input {
    border-right-width: 2px !important;
  }

  .ant-input-number:not(.ant-input-number-disabled):hover .ant-input-number-input,
  .ant-input-number-focused .ant-input-number-input {
    border: var(--input-border--focus);
  }

  .ant-input-number .ant-input-number-handler-wrap {
    height: 36px;
    margin-top: 2px;
    margin-right: 2px;
  }

  .ant-input-number-sm input {
    height: 32px;
  }

  .ant-input-number-sm .ant-input-number-handler-wrap {
    height: 28px;
  }
}
