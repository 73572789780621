body {
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 8px;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first:after,
  .ant-table-ping-right .ant-table-cell-fix-right-last:after {
    box-shadow: inset -1px 0 0 var(--border-color);
  }

  .ant-table-ping-left .ant-table-cell-fix-left-first:after,
  .ant-table-ping-left .ant-table-cell-fix-left-last:after {
    box-shadow: inset 1px 0 0 var(--border-color);
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
    width: 1px;
    box-shadow: inset -1px 0 0 var(--border-color);
  }

  .ant-table-cell-fix-left-first:after,
  .ant-table-cell-fix-left-last:after {
    width: 1px !important;
  }

  .table .ant-table-tbody > tr td {
    color: var(--text-color);
  }

  .ant-table-column-sorter-inner {
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border: 1px solid var(--accent-border-color);
    border-radius: 2px;
  }

  .table .ant-table-tbody > tr:last-child > td {
    border-bottom: 1px solid var(--accent-border-color);
  }

  .body__content-wr--no-padding_true .ant-table-cell:first-child {
    padding-left: 16px;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    border-bottom: 1px solid var(--border-color);
    background-color: transparent;
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: #fafafa;
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
