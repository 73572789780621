body {
  .ant-tooltip {
    box-shadow: var(--box-shadow-modal);
    font-size: 13px;
    line-height: 1.24;
  }

  .ant-tooltip-inner {
    min-height: auto;
    background: var(--app-work-bg);
    color: var(--text-color);
  }

  .ant-tooltip-arrow-content {
    background: var(--app-work-bg);
  }
}
