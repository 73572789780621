body {
  .ant-pagination-item {
    border: 0;
    border-radius: 4px;
    transition: .2s;
  }

  .ant-pagination-item-active {
    background: var(--accent-bg-color) !important;
  }

  .ant-pagination-item-active a {
    font-weight: 400;
    color: var(--accent-color) !important;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: 0;
    background: transparent;
  }

  .ant-pagination-item-link {
    border: 0;
    border-radius: 4px;
  }

  .ant-pagination-next {
    padding: 0 10px;
    border-radius: 4px;
    transition: .2s;
  }

  .ant-pagination-prev {
    padding: 0 10px;
    border-radius: 4px;
    transition: .2s;
  }

  .ant-pagination-prev.ant-pagination-disabled {
    color: var(--gray-color);
  }

  .ant-pagination-next.ant-pagination-disabled {
    color: var(--gray-color);
  }

  .ant-pagination-item:hover {
    background: var(--input-bg-color);
  }

  .ant-pagination-next:hover {
    background: var(--input-bg-color);
    color: var(--input-color);
  }

  .ant-pagination-prev:hover {
    background: var(--input-bg-color);
  }

  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: var(--text-color);
  }

  .ant-pagination-options {
    display: none;
  }
}
