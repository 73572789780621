body {
  .ant-picker,
  .ant-picker.ant-picker-disabled {
    border: var(--input-border--default);
    border-radius: 4px;
    box-shadow: none;
    background: var(--input-bg-color);
  }

  .ant-picker-disabled:hover {
    border-right-width: 2px !important;
  }

  .ant-picker:not(.ant-picker-disabled):hover {
    border: var(--input-border--focus) !important;
    box-shadow: none;
  }

  .ant-picker:not(.ant-picker-disabled):focus-within {
    border: var(--input-border--focus) !important;
    box-shadow: none;
    background: var(--white-color);
  }

  .ant-picker-input > input {
    color: var(--text-color);
  }

  .ant-picker-input > input::placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  }

  .ant-picker-suffix,
  .ant-picker-prefix {
    font-size: 18px;
    color: var(--text-color);
  }
}
