body {
  .ant-tree .ant-tree-switcher .ant-tree-switcher-icon,
  .ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon {
    font-size: 16px !important;
  }

  .form-tree .ant-tree-treenode-switcher-close,
  .form-tree .ant-tree-treenode-switcher-open {
    padding-top: 12px;
  }

  .ant-tree .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-left: 8px;
    font-size: 16px !important;
  }

  .ant-tree-indent-unit {
    margin-left: 8px;
  }

  .ant-tree .ant-tree-switcher.ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree .ant-tree-node-content-wrapper {
    padding: 0;
  }

  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }

  .ant-tree-list-holder-inner > .ant-tree-treenode > .ant-tree-switcher > .ant-tree-switcher-leaf-line {
    display: none;
  }

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: var(--white-color);
  }

  .ant-tree-list-holder-inner > .ant-tree-treenode > .ant-tree-indent ~ .ant-tree-switcher > .ant-tree-switcher-leaf-line {
    display: inline-block;
  }

  .ant-tree-show-line .ant-tree-indent-unit:before {
    content: none;
    top: calc(100% - 18px);
  }

  .ant-tree-show-line .ant-tree-indent-unit:after {
    content: '';
    position: absolute;
    top: calc(100% - 36px - 16px) !important;
    right: 11px !important;
    bottom: -4px;
    border-right: 2px solid var(--accent-border-color) !important;
  }

  .ant-tree .ant-tree-switcher-leaf-line:after {
    display: none;
  }

  .ant-tree .ant-tree-switcher-leaf-line:before {
    display: none;
  }
}
