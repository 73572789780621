body {
  .ant-menu-item {
    transition: none;
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    color: var(--gray-color) !important;
  }
}
