body {
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    border: var(--input-border--default);
    background: var(--input-bg-color);
  }

  .ant-select-show-search.ant-select-multiple:not(.ant-select-disabled) .ant-select-selector:hover {
    border: var(--input-border--focus);
  }

  .ant-select-show-search.ant-select-multiple:not(.ant-select-disabled) .ant-select-selector:focus-within {
    border: var(--input-border--focus);
  }

  //default
  .ant-select .ant-select-selector {
    border: var(--input-border--default);
    box-shadow: none;
    background: var(--input-bg-color);
  }

  .ant-select-item {
    margin: 2px 8px;
    border-radius: var(--border-radius);
    transition: none;
  }

  .rc-virtual-list-holder-inner .ant-select-item:first-child {
    margin-top: 6px;
  }

  .rc-virtual-list-holder-inner .ant-select-item:last-child {
    margin-bottom: 6px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: var(--input-border--default);
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: var(--input-border--default) !important;
    box-shadow: none;
    background: var(--input-bg-color);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: var(--input-border--default) !important;
    border-color: var(--input-bg-color);
    box-shadow: none;
    background: var(--input-bg-color);
  }

  .ant-select:not(.ant-select-disabled).ant-select-multiple:not(.ant-select-customize-input):hover .ant-select-selector {
    border: var(--input-border--focus) !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    border: var(--input-border--focus) !important;
    box-shadow: none;
    background: var(--white-color);
  }

  .ant-select-selector {
    display: flex;
    min-height: 40px;
    align-items: center;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    color: var(--text-color);
  }

  .ant-select-sm .ant-select-selector {
    min-height: 32px;
  }

  .ant-select-single .ant-select-selector {
    background-color: var(--input-bg-color) !important;
  }

  .ant-select-focused.ant-select-multiple .ant-select-selector {
    background: var(--white-color) !important;
  }

  .ant-select-multiple .ant-select-selection-item {
    display: flex;
    height: 30px !important;
    align-items: center;
    padding: 0 8px !important;
    border: 1px solid var(--accent-border-color) !important;
    background: var(--white-color) !important;
    color: var(--text-color);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
    color: var(--text-color);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
    background: var(--input-bg-color);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus-within {
    background: var(--input-bg-color);
  }

  .ant-select.ant-select-single.ant-select-show-arrow.ant-select-open .ant-select-selector {
    border-color: var(--input-bg-color);
    background: var(--input-bg-color);
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    opacity: 1;
    color: var(--text-color);
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: var(--white-color);
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item-content {
    display: flex;
  }

  .ant-select-selection-placeholder {
    font-weight: 400;
    text-transform: none;
    color: var(--input-placeholder-color);
  }

  .ant-select-selector:after {
    content: '' !important;
    display: flex !important;
    width: 24px !important;
    height: 24px !important;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto !important;
    background: url(../../icons/icons/arrow-thin.svg) no-repeat center center;
    background-size: 25px 25px;
    font-size: 1px;
    line-height: 1 !important;
    transition: transform .5s;
    transform: translateX(4px);
    color: var(--text-color);
  }

  .ant-select-open .ant-select-selector:after {
    transform: translateX(4px) rotate(180deg);
  }

  .ant-select-multiple .ant-select-selector:after {
    content: url(../../icons/icons/enter.svg) !important;
    background: none;
    transform: translateX(-3px);
    color: var(--gray-color);
  }

  .ant-select-arrow {
    display: none !important;
  }

  .ant-select-single .ant-select-selector:after,
  .ant-select-single .ant-select-selector .ant-select-selection-item:after,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder:after {
    visibility: visible !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--input-bg-color);
  }

  .ant-select-disabled .ant-select-selector:after {
    opacity: .24;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding-top: 6px;
  }
}
