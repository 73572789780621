body {
  .ant-form-vertical .ant-form-explain {
    display: flex;
    height: 23px;
    min-height: 15px;
    align-items: center;
    margin-top: 0 !important;
    padding-bottom: 4px;
    font-size: 13px;
    line-height: 15px;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 11px;
    line-height: 1.5715;
    letter-spacing: 0;
    text-align: left;
    color: var(--gray-color);
  }

  .ant-form-item-label > label:after {
    content: none;
  }
}
