.fallback-default {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fallback-default__text {
  margin-top: 24px;
  color: rgba(0, 0, 0, .25);
}

.fallback-default--is-absolute_true {
  position: absolute;
}

.fallback-default--is-static_true {
  position: static;
  width: 100%;
  padding: 40px 0;
}

@font-family: Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color: #1D2B4B;@primary-color: #01b075;@border-radius-base: 4px;@border-color-base: #E9EBF0;@slider-handle-color: #01b075;@slider-track-background-color: #01b075;@slider-track-background-color-hover: #01b075;@slider-rail-background-color-hover: #f0f0f0;@slider-rail-background-color: #f0f0f0;@slider-dot-border-color-active: #01b075;@slider-handle-color-hover: #01b075;@table-selection-column-width: 40px;