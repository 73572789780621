body {
  .ant-switch:not(.ant-switch-checked) {
    box-shadow: none;
    background-color: var(--gray-color);
  }

  .ant-switch.ant-switch-checked {
    box-shadow: none;
    background-color: var(--accent-color);
  }
}
