:root {
  --first-breadcrumbs-link-with-setting-pagination__title: 270px;
}

body {
  .body__title .ant-breadcrumb-link {
    padding: 0 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .body__title .body__title-breadcrumbs .ant-breadcrumb-link {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .body__title .ant-breadcrumb-link:hover {
    background: var(--btn-default--bg__hover);
  }

  .body__title .ant-breadcrumb-overlay-link .ant-breadcrumb-link {
    background: inherit;
  }

  .body__title .ant-breadcrumb > span:last-child {
    max-width: calc(100vw - var(--editor-width) - var(--first-breadcrumbs-link-with-setting-pagination__title));
    overflow: hidden;
    color: rgba(0, 0, 0, .45);
  }

  .body__title .ant-breadcrumb-separator {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .body__title .ant-breadcrumb .anticon {
    font-size: 16px;
  }

  .body__title .ant-dropdown-trigger.ant-breadcrumb-overlay-link,
  .body__title .ant-breadcrumb-link {
    display: flex;
    align-items: center;
  }

  .body__title .ant-breadcrumb-link + .anticon {
    display: none;
  }

  .body__title .ant-breadcrumb {
    display: flex;
    height: 32px;
    min-width: 100%;
    align-items: center;
    font-size: 20px;
    line-height: 32px;
    color: var(--text-color);
  }

  .body__title .ant-breadcrumb > span {
    display: flex;
    align-items: center;
  }
}
