body {
  .ant-notification-hook-holder,
  .ant-notification-notice {
    box-sizing: border-box;

    /* grey delimeters */

    border: 1px solid hsl(223, 19%, 93%);
    border-radius: 6px;
    box-shadow: none;
    background: #f8f9fb;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 0;
    margin-left: 32px;
    font-weight: normal;
    font-style: normal;
    font-size: 13px;
    line-height: 22px;
    color: var(--text-color);
  }

  .ant-notification-notice-icon {
    display: block;
    height: 20px;
    margin: 2px 0 0;
    font-size: 20px;
    line-height: 1;
  }
}
